var app = app || {};

var spBreak = 768;

app.init = function() {
  app.menu();
  app.viewport();
  app.rightscroll();
  app.getAct();
  app.btnTop();
};

app.isMobile = function() {

  return window.matchMedia('(max-width: ' + spBreak + 'px)').matches;

};

app.isOldIE = function() {

  return $('html.ie9').length || $('html.ie10').length;

};

app.menu = function() {
    $('.menu-trigger').on('click',function(){
      $(this).toggleClass('active');
      if($(this).hasClass('active')) {
        $('.gnav').addClass('is-open');
      } else {
        $('.gnav').removeClass('is-open');
      }
    });
};
app.btnTop = function () {

  var topBtn = $('.back-top');
  topBtn.hide();
  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      topBtn.fadeIn();
    } else {
      topBtn.fadeOut();
    }
  });
  //スクロールしてトップ
  topBtn.click(function () {
  $('body,html').animate({
    scrollTop: 0
  }, 500);
  return false;
  });

  var rightmenu = $('.right-menu');
  rightmenu.hide();
  $(window).scroll(function () {
    if (app.isMobile()) {
      rightmenu.hide();
    } else {
      if ($(this).scrollTop() > 1000) {
        rightmenu.fadeIn();
      } else {
        rightmenu.fadeOut();
      }
    }

  });


  var topBtnsp = $('.sp-top');
  if (app.isMobile()) {
    topBtnsp.hide();
    $(window).scroll(function () {
      if ($(this).scrollTop() > 150) {
        topBtnsp.fadeIn();
      } else {
        topBtnsp.fadeOut();
      }
    });
  } else {
    topBtnsp.fadeOut();
  }
  //スクロールしてトップ
  topBtnsp.click(function () {
  $('body,html').animate({
    scrollTop: 0
  }, 500);
  return false;
  });
};
app.viewport = function() {

  var metaViewport = document.getElementById('viewport');
  var tabletWidth = 751; // sm breakpoint + 1
  var pcWidth = 1000; // depend your site container

  var viewports = {
    default: metaViewport.getAttribute('content'),
    tablet: 'width=1000, user-scalable=0'
  };

  var viewportSet = function() {
    if (screen.width >= tabletWidth && screen.width < pcWidth) {
      metaViewport.setAttribute('content', viewports.tablet);
    } else {
      metaViewport.setAttribute('content', viewports.default);
    }
  };

  viewportSet();

  window.onload = function() {
    viewportSet();
  };

  window.onresize = function() {
    viewportSet();
  };
};

app.getAct = function() {
  $(document).on("scroll", onScroll);

  //smoothscroll
  $('a.scroll[href^="#"]').on('click', function (e) {
      e.preventDefault();
      $(document).off("scroll");

      $('a.scroll').each(function () {
          $(this).removeClass('active');
      })
      $(this).addClass('active');

      var target = this.hash,
          menu = target;
      $target = $(target);
      $('html, body').stop().animate({
          'scrollTop': $target.offset().top+2
      }, 500, 'swing', function () {
          window.location.hash = target;
          $(document).on("scroll", onScroll);
      });
  });
};

app.rightscroll = function () {
  $(window).scroll(function () {
    var scrollPos = $(document).scrollTop();
  // console.log(scrollPos);
  if (scrollPos >= 7000) {
    $('.right-menu').css({"position": "absolute", "bottom": "35rem"});
  } else {
    $('.right-menu').css({"position": "fixed", "bottom": "10rem"})
  }

  });
};

function onScroll(event){
  var scrollPos = $(document).scrollTop();
  $('#menu-center a').each(function () {
      var currLink = $(this);
      var refElement = $(currLink.attr("href"));
      if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
          $('#menu-center ul li a').removeClass("active");
          currLink.addClass("active");
      }
      else{
          currLink.removeClass("active");
      }
  });
}
$(function() {

  app.init();

});
